














































import { Vue, Component } from 'vue-property-decorator';
import UserProfilePersonalityProfileTabViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-personality-profile-tab-view-model';

@Component({
  name: 'UserProfilePersonalityProfileTab',
  components: {},
})
export default class UserProfilePersonalityProfileTab extends Vue {
  personality_profile_tab_view_model = Vue.observable(
    new UserProfilePersonalityProfileTabViewModel(),
  );

  created() {
    this.personality_profile_tab_view_model.initialize();
  }
}
